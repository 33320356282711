import React from "react";
import Carasoul from "../../Components/Carasoul/Carasoul";
// import Footer from "../../Components/Footer/Footer";
import "./Homepage.scss";
// import hp_girl from "../../Assets/hp_girl.png";
import Animtxt from "../../Components/Animtxt";
import Button3D from "../../Components/Buttons/Button3D";
import { Controls, PlayState, Reveal, Tween } from "react-gsap";
import Spinner from "../../Assets/Spinner.svg";
// import ProductsMain from "../Products/ProductsMain";
import Ps from "../../Components/Carasoul/Ps";
// import ProductsCarasoul from "../../Components/Carasoul/ProductsCarasoul";
import a from "../../Assets/Frames/201345.svg";
import b from "../../Assets/Frames/202769.svg";
import c from "../../Assets/Frames/bolon.svg";
import d from "../../Assets/Frames/burberry-1.svg";
import e from "../../Assets/Frames/calvin-klein-1.svg";
import f from "../../Assets/Frames/carrera-2.svg";
import h from "../../Assets/Frames/download.jpg";
import hl from "../../Assets/Frames/Dorrit Dekk logo.svg";

import i from "../../Assets/Frames/download.png";
import j from "../../Assets/Frames/emporio-armani-2.svg";
import k from "../../Assets/Frames/esprit-1.svg";
import m from "../../Assets/Frames/montblanc-logo-vector.svg";
import n from "../../Assets/Frames/oakley-sunglasses-logo-vector.svg";
import o from "../../Assets/Frames/ray-ban-logo-vector.svg";
import p from "../../Assets/Frames/Silhouette-Logo-Black-transparent-background.png";
import q from "../../Assets/Frames/swarovski-crystal-logo.png";
import r from "../../Assets/Frames/tom-ford-eyewear-logo-vector.svg";
import s from "../../Assets/Frames/tommy-hilfiger-2.svg";
import t from "../../Assets/Frames/vogue.svg";
import ca from "../../Assets/ContactLens/182923.svg";
import cc from "../../Assets/ContactLens/alcon.svg";
import cd from "../../Assets/ContactLens/bausch-lomb-2.svg";
import ce from "../../Assets/ContactLens/johnson-johnson-4.svg";
import TextG from "../../Components/TextG";
import { Link } from "react-router-dom";
import { useLayoutEffect } from "react";

function Homepage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const brands = [
    "https://www.safilogroup.com/Safilo.svg",

    a,
    b,
    c,
    d,
    e,
    f,
    "https://www.gotti.ch/build/img/gotti_logo.svg",
    hl,
    i,
    j,
    k,
    "https://eyecontactoptician.com/wp-content/uploads/2021/06/acme-logo.jpg",
    m,
    n,
    o,
    p,
    q,
    r,
    s,
    t,
    ca,
    "https://www.bauschandlomb.in/static/img/logo.png",

    cc,

    ce,
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Fpolaroid-1%20-%20Copy.svg?alt=media&token=89912881-8931-4442-94ca-cae242cd2bde",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Fscott-sports-vector-logo.svg?alt=media&token=3db551eb-22ff-4f44-b723-d3d40fb92e33",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Foakley-sunglasses-logo-vector%20-%20Copy.svg?alt=media&token=e8932236-06b1-46d6-bcc3-f4caf5843d7d",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Fmauijimlogo%20-%20Copy.png?alt=media&token=cce6ec5b-ad4c-4bdd-bb47-65ba35fdfdda",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Fdownload%20-%20Copy.jpg?alt=media&token=119ca766-e5b8-491e-8e73-c681c0903357",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Fc7f378f51fa19878f093%20-%20Copy.png?alt=media&token=f162573d-ae78-4ad1-b1b9-129ec3d86ddd",
  ];
  // const frames = [a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t];

  const eye_wear = [
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2FDB0CE792-03AC-44AB-BEE3-A739BC0637C9.jpg?alt=media&token=0ae27619-b9dd-4167-9714-fd68e037983b",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2FA93264FD-93E7-4E76-86A8-97E27F30356D.jpg?alt=media&token=c1b087b6-eee5-4491-9d24-258ab15f816c",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F9F308682-329A-4FDA-8247-3B5EAABFB884.jpg?alt=media&token=6d2322b8-1d1b-4b14-8f23-56f48526d045",
  ];
  const sunglasses = [
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F0616A65E-5D2D-4907-B4B0-9EA047A7983A.jpg?alt=media&token=3dc92ba5-cd9a-4a03-88fe-e9ad8e14cbc1",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F5D47551B-8B61-4157-9192-E1DA697977E9%20(1).jpg?alt=media&token=e6477d4a-4bc0-491f-8581-3764e65efaf7",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F59868B13-3B55-4AA6-9058-3C8E757DD487.jpg?alt=media&token=5a352fe5-5f72-4c84-9be9-cd34443420b8",
  ];
  const homecar = [
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/HomeCar%2FIMG_2907.jpg?alt=media&token=ceb01e16-8d74-45ba-bd81-d838e7c51a39",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/HomeCar%2Fsai-kiran-anagani-83zRhEhFMfo-unsplash-min-min.jpg?alt=media&token=19f997ea-0176-4fb4-9dd1-56de6cb824d0",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/HomeCar%2Fclose-up-portrait-excited-three-girls-laughing-during-meeting-indoor-photo-good-looking-ladies-colorful-sunglasses-enjoying-free-time-together-min-min.jpg?alt=media&token=bbccf9c7-9968-4498-8182-cfb231838796",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/HomeCar%2Fclose-up-photo-inspired-trendy-lady-sparkle-glasses-looking-up-with-mouth-open-min-min.jpg?alt=media&token=82642357-a1a7-4699-9e5b-78426af4b5bb",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/HomeCar%2Fantonino-visalli-F1tBhG6F4zI-unsplash-min-min.jpg?alt=media&token=bd269233-5402-4b2d-a2ef-f1f0f92ac0a2",
    // "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/HomeCar%2Foffer.svg?alt=media&token=458b88ab-b833-48c4-83b9-e481b28ad5f6",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/ContactUs%2FIMG_2899.JPG?alt=media&token=411ae1f9-7067-4ac1-9f05-d26c221df868",
  ];

  return (
    <div className="homepage">
      <div className="welcome_hp">
        <div className="hp_girl">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Homepage%2Fhp_girl-min.png?alt=media&token=c92b3853-3d85-47bf-af7e-8015ad3b22ff"
            alt=""
          ></img>
        </div>
        <div className="hp_back">
          <div className="back_left">
            <Animtxt />
            <Tween from={{ opacity: 0 }} duration={3} ease="back.out(1.7)">
              <div className="button_hp">
                <Button3D />
              </div>
            </Tween>
          </div>
          <div className="back_right">
            <div className="spinner">
              <img src={Spinner} alt="Spinner"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="about_hp">
        <div className="about_hp_2">
          <video
            poster="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Stores%2Fcb158e9e-82bd-4546-a626-7a55e697013e.jpg?alt=media&token=f074b5fd-7308-4a1e-ac88-7a858421c7c6"
            autoPlay
            loop
            muted
            playback="2.0"
            playsinline
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Videos%2F20210915-165800-552.MP4.mp4?alt=media&token=1e0a2155-fb3d-45e5-a08e-b36cd251726f"
              type="video/mp4"
            />
          </video>
          <div></div>
        </div>
        <div className="about_hp_1">
          <p>
            Kohinoor Optics is committed to delivering excellence in eye care
            and eye fashion for more than 55 years. We have grown into a chain
            of optical retail stores with the trust of thousands of customers by
            offering specialized, verified, and trained Optometrists; Wide range
            of Sunglasses, Spectacles and Contact Lens Clinic. Taking Care of
            your beautiful eyes Since 1965.
          </p>
        </div>
      </div>

      <div className="hero">
        <h1>"WE ARE NOW IN THIS OVER 5 DECADES"</h1>
      </div>
      <Ps img={homecar} />
      {/* <ProductsMain imgs={eye_wear} heading={"Products"} /> */}
      <div className="be_awsome">
        <Reveal repeat={false}>
          <TextG
            dur={2}
            txt={{ text: "Caretaker Of Your Beautiful Eyes Since 1965" }}
          >
            <h1>TYFRUO</h1>
          </TextG>
        </Reveal>
      </div>

      <div className="be_awsome">
        <Reveal repeat={false}>
          <TextG dur={2} txt={{ text: "Authorized Dealer" }}>
            <h1>TYFRUO</h1>
          </TextG>
        </Reveal>
      </div>
      <Carasoul img={brands} />
      <div className="be_awsome">
        <Reveal repeat={false}>
          <TextG dur={2} txt={{ text: "Find Your Nearest Store " }}>
            <h1>TYFRUO</h1>
          </TextG>
        </Reveal>
      </div>
      <div className="find_store">
        <Link to="/contact_us" class="button">
          <p>Contact Us</p>
        </Link>
      </div>
    </div>
  );
}

export default Homepage;
