import React from "react";
import "./VisionCare.scss";
import TextG from "../../Components/TextG";
import DocProfile from "../../Components/DocProfile";
// import vc_girl from "../../Assets/vc_girl.svg";
import Ps from "../../Components/Carasoul/Ps";
import { useLayoutEffect } from "react";

function VisionCare() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const VisonCare = [
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/VisonCare%2FIMG_2914-min.JPG?alt=media&token=1bfd3a14-0077-4400-a46e-b29c5f720a24",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/VisonCare%2FIMG_2839-min.JPG?alt=media&token=1f18d5dc-db16-4960-b460-7f4d3444d283",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/VisonCare%2FIMG_2836-min.JPG?alt=media&token=a9d68992-7a8e-4bf5-b79f-ee20f87302bf",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/VisonCare%2FIMG_2835-min.JPG?alt=media&token=52f90841-b83b-4b89-8f82-08de38f94f9f",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/VisonCare%2FIMG_2833-min.JPG?alt=media&token=4d9ee57f-5a7f-4b53-8d3e-ae79769fce18",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/VisonCare%2FIMG_2831-min.JPG?alt=media&token=39b70baa-ae6b-4ec8-b4b8-4e5442f8df30",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/VisonCare%2FIMG_2829-min.JPG?alt=media&token=dfce379e-7a37-4e77-b372-36628c59cb34",
  ];
  return (
    <div className="vision_care">
      <div className="vc_welcome">
        <div className="vc_txt">
          <TextG dur={2} txt={{ text: "VISION CARE" }}>
            <h1>VC</h1>
          </TextG>
        </div>
      </div>
      <div className="vc_info">
        <h1>"To Care of your vision"</h1>
      </div>
      <DocProfile
        right={true}
        img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/VisonCare%2FIMG_2833-min.JPG?alt=media&token=4d9ee57f-5a7f-4b53-8d3e-ae79769fce18"
      >
        Kohinoor optics is in the field of Optometry for decades. Primarily
        providing specialize visual health and eye care. Our expertise in vision
        screening, diagnosis, and Optometric counseling made us shine through
        the market.
      </DocProfile>
      <DocProfile img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/VisonCare%2FIMG_2914-min.JPG?alt=media&token=1bfd3a14-0077-4400-a46e-b29c5f720a24">
        Taking care of your eyes should be an experience of elegance and joy.
        Here at Kohinoor optics, we pride ourselves on our unparalleled customer
        care. With expert craftsmanship, our Optometrists use revolutionary
        machinery and equipment because your eye deserves the very best.
      </DocProfile>
      <div className="vc_hc">
        <Ps img={VisonCare} />
      </div>
    </div>
  );
}

export default VisionCare;
