import React, { useState } from "react";
import "./Header.scss";
import logo from "../../Assets/Final_KohinoorLogo.svg";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import { Tween, Reveal } from "react-gsap";

function Header() {
  const [click, setClick] = useState(false);
  let icon;
  if (click) {
    icon = (
      <div className="ham" onClick={() => setClick(!click)}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
    // <CloseIcon className="nav_icons" onClick={() => setClick(!click)} />;
  } else {
    icon = <MenuIcon className="nav_icons" onClick={() => setClick(!click)} />;
  }
  return (
    <div className={`header ${click && "open_header"}`}>
      <div className="logo">
        <NavLink to="/">
          <img src={logo} alt=""></img>
        </NavLink>
      </div>

      <div className="elements">
        <div className="element">
          <Reveal repeat={true}>
            <Tween from={{ y: "100px", opacity: 0 }} duration={0.5}>
              <NavLink to="products" onClick={() => setClick(!click)}>
                Products
              </NavLink>
            </Tween>
          </Reveal>
        </div>
        <div className="element">
          <Reveal repeat={true}>
            <Tween from={{ y: "100px", opacity: 0 }} duration={0.8}>
              <NavLink to="about_us" onClick={() => setClick(!click)}>
                About Us
              </NavLink>
            </Tween>
          </Reveal>{" "}
        </div>
        <div className="element">
          <Reveal repeat={true}>
            <Tween from={{ y: "100px", opacity: "0" }} duration={1.1}>
              <NavLink to="vision_care" onClick={() => setClick(!click)}>
                Vision Care
              </NavLink>
            </Tween>
          </Reveal>
        </div>
        <div className="element">
          <Reveal repeat={true}>
            <Tween from={{ y: "100px", opacity: 0 }} duration={1.4}>
              <NavLink to="contact_us" onClick={() => setClick(!click)}>
                Contact Us
              </NavLink>
            </Tween>
          </Reveal>
        </div>
      </div>
      <div className="nav_icon">
        <button
          className={`menu ${click && "opened"}`}
          onClick={() => setClick(!click)}
          aria-label="Main Menu"
        >
          <svg width="100" height="100" viewBox="0 0 100 100">
            <path
              class="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path class="line line2" d="M 20,50 H 80" />
            <path
              class="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Header;
