import React from "react";
import "./Animtxt2.scss";

function Animtxt(props) {
  return (
    <div class="animated-title2">
      <div class="text-top">
        <div>
          <span>{props.line1}</span>
          <span>{props.line2}</span>
        </div>
      </div>
      <div class="text-bottom">
        <div>{props.line3}</div>
      </div>
    </div>
  );
}

export default Animtxt;
