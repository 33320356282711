import React from "react";
import Slider from "react-slick";

import "./Carasoul.scss";

export default function Carasoul(props) {
  const images = props.img;
  var settings = {
    // dots: true,

    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    arrow: false,
  };
  return (
    <div className="car">
      <Slider {...settings}>
        {images.map((image) => {
          return (
            <div>
              <img src={image} alt=""></img>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
