import React from "react";
import "./Wa.scss";
import wa from "../Assets/wa.svg";

function Wa() {
  return (
    <div className="wa">
      <a href=" https://wa.me/919510626265?text=I'm%20interested%20in%20your%20Services">
        <img src={wa} alt=""></img>
      </a>
      <a id="ins" href="https://www.instagram.com/kohinooroptics/">
        <img src="https://img.icons8.com/fluency/200/000000/instagram-new.png" />{" "}
      </a>
      <a href=" https://m.facebook.com/profile.php?id=100053076620921">
        <img src="https://img.icons8.com/external-justicon-lineal-color-justicon/64/000000/external-facebook-social-media-justicon-lineal-color-justicon.png" />
      </a>
    </div>
  );
}

export default Wa;
