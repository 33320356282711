import React from "react";
import "./Products.scss";
import TextG from "../../Components/TextG";
import sungalsses from "../../Assets/sunglasses.jpg";
import ProductsMain from "./ProductsMain";
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Carasoul from "../../Components/Carasoul/Carasoul";
import a from "../../Assets/Frames/201345.svg";
import b from "../../Assets/Frames/202769.svg";
import c from "../../Assets/Frames/bolon.svg";
import d from "../../Assets/Frames/burberry-1.svg";
import e from "../../Assets/Frames/calvin-klein-1.svg";
import f from "../../Assets/Frames/carrera-2.svg";
import h from "../../Assets/Frames/download.jpg";
import i from "../../Assets/Frames/download.png";
import j from "../../Assets/Frames/emporio-armani-2.svg";
import k from "../../Assets/Frames/esprit-1.svg";
import l from "../../Assets/Frames/gotti.svg";
import m from "../../Assets/Frames/montblanc-logo-vector.svg";
import n from "../../Assets/Frames/oakley-sunglasses-logo-vector.svg";
import o from "../../Assets/Frames/ray-ban-logo-vector.svg";
import p from "../../Assets/Frames/Silhouette-Logo-Black-transparent-background.png";
import q from "../../Assets/Frames/swarovski-crystal-logo.png";
import r from "../../Assets/Frames/tom-ford-eyewear-logo-vector.svg";
import s from "../../Assets/Frames/tommy-hilfiger-2.svg";
import t from "../../Assets/Frames/vogue.svg";
import ca from "../../Assets/ContactLens/182923.svg";
import cb from "../../Assets/ContactLens/acme-1.svg";
import cc from "../../Assets/ContactLens/alcon.svg";
import ce from "../../Assets/ContactLens/johnson-johnson-4.svg";
import { useRef } from "react";
import { useLayoutEffect } from "react";

function Products() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const myRef = useRef();
  const executeScroll = () => myRef.current.scrollIntoView();
  const sun = useRef();
  const sunScroll = () => sun.current.scrollIntoView();
  const contact = useRef();
  const contactscroll = () => contact.current.scrollIntoView();
  const eye_wear = [
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2FDB0CE792-03AC-44AB-BEE3-A739BC0637C9.jpg?alt=media&token=0ae27619-b9dd-4167-9714-fd68e037983b",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2FA93264FD-93E7-4E76-86A8-97E27F30356D.jpg?alt=media&token=c1b087b6-eee5-4491-9d24-258ab15f816c",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F9F308682-329A-4FDA-8247-3B5EAABFB884.jpg?alt=media&token=6d2322b8-1d1b-4b14-8f23-56f48526d045",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F4D5FA71A-D878-4636-9108-C8D453EB1DF3.jpg?alt=media&token=c9e69795-6632-460d-9132-8dd20e530067",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F490D6B38-B60A-4D64-ACFC-A8644BF4F747.jpg?alt=media&token=89d3fec5-f842-43ef-9595-c581bc9632bf",
  ];
  const frames = [
    a,
    b,
    c,
    d,
    e,
    f,

    h,
    i,
    j,
    k,
    "https://www.gotti.ch/build/img/gotti_logo.svg",
    "https://www.safilogroup.com/Safilo.svg",

    m,
    n,
    o,
    p,
    q,
    r,
    s,
    t,
  ];
  // const frames = [a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t];
  const contact_lens = [
    ca,
    "https://eyecontactoptician.com/wp-content/uploads/2021/06/acme-logo.jpg",

    cc,
    "https://www.bauschandlomb.in/static/img/logo.png",
    ce,
  ];
  const goggle = [
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Fpolaroid-1%20-%20Copy.svg?alt=media&token=89912881-8931-4442-94ca-cae242cd2bde",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Fscott-sports-vector-logo.svg?alt=media&token=3db551eb-22ff-4f44-b723-d3d40fb92e33",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Foakley-sunglasses-logo-vector%20-%20Copy.svg?alt=media&token=e8932236-06b1-46d6-bcc3-f4caf5843d7d",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Fmauijimlogo%20-%20Copy.png?alt=media&token=cce6ec5b-ad4c-4bdd-bb47-65ba35fdfdda",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Fdownload%20-%20Copy.jpg?alt=media&token=119ca766-e5b8-491e-8e73-c681c0903357",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Goggle%2Fc7f378f51fa19878f093%20-%20Copy.png?alt=media&token=f162573d-ae78-4ad1-b1b9-129ec3d86ddd",
  ];
  const goggle_frames = [
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F9FECE0A8-427C-4B7C-8F58-8F6EC87B3D7F.jpg?alt=media&token=8f32e97d-dc95-4c3a-8e44-1e510f855033",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F5D47551B-8B61-4157-9192-E1DA697977E9%20(1).jpg?alt=media&token=e6477d4a-4bc0-491f-8581-3764e65efaf7",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F59868B13-3B55-4AA6-9058-3C8E757DD487.jpg?alt=media&token=5a352fe5-5f72-4c84-9be9-cd34443420b8",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F39110B02-6947-43DC-A747-0D2867B105EC.jpg?alt=media&token=ff5748e4-d943-4619-9387-9193bcab73fe",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Frames%2F0616A65E-5D2D-4907-B4B0-9EA047A7983A.jpg?alt=media&token=3dc92ba5-cd9a-4a03-88fe-e9ad8e14cbc1",
  ];
  const contact_lens_products = [
    // "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/ContactLens%2FWhatsApp%20Image%202021-09-14%20at%206.16.13%20PM.jpeg?alt=media&token=8dd125ce-9528-4e73-9fb8-3cd11025d2e0",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/ContactLens%2FWhatsApp%20Image%202021-09-14%20at%206.16.02%20PM.jpeg?alt=media&token=40aec4da-f086-4a85-976d-22e478161036",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/ContactLens%2FIMG_20210923_161657-min.jpg?alt=media&token=f6f7832e-0904-4123-97d1-66fa88563947",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/ContactLens%2FWhatsApp%20Image%202021-09-14%20at%206.14.48%20PM%20(1).jpeg?alt=media&token=e706535c-b94f-4327-bd25-fe80041a4764",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/ContactLens%2FWhatsApp%20Image%202021-09-14%20at%206.07.10%20AM.jpeg?alt=media&token=3052b480-2033-4d40-aa0f-e60d59f57106",
    "https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/ContactLens%2FWhatsApp%20Image%202021-09-14%20at%206.07.21%20AM.jpeg?alt=media&token=4bed9154-d98e-4e75-bf7f-cb22b76e5fc7",
  ];

  // const eye_wear = ["hi", "hello", "good morning"];
  return (
    <div className="products">
      <div className="products_welcome">
        <div className="p_txt">
          <TextG dur={2} txt={{ text: "Products" }}>
            <h1>Pcut</h1>
          </TextG>
        </div>
      </div>
      <div className="products_div">
        <div className="eye_wear" onClick={executeScroll}>
          <h1>Spectacles</h1>
          {/* <Link to="/products/eye_wear_1">
            <img src={sungalsses} alt=""></img>
          </Link> */}

          <img
            src="https://images.unsplash.com/photo-1574375335191-1cc8684453c3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            alt=""
          ></img>
        </div>
        <div className="sunglasses" onClick={sunScroll}>
          <h1>sunglasses</h1>

          <img src={sungalsses} alt=""></img>
        </div>
        <div className="contact_lenses" onClick={contactscroll}>
          <h1>Contact Lenses</h1>

          <img
            src="https://media.istockphoto.com/photos/woman-inserting-a-contact-lens-in-eye-picture-id507172508?k=20&m=507172508&s=612x612&w=0&h=EaXjYlJjAbljCamCe8xcfOAxImL_bn4T5G8sc2V9nj8= "
            alt=""
          ></img>
        </div>
      </div>

      {/* <ProductsMain id="id-eye-glasses" imgs={eye_wear} heading={"Eye Wear"} /> */}
      <div ref={myRef}></div>
      <ProductsMain
        imgs={eye_wear}
        heading={"Spectacles"}
        text={
          "At Kohinoor optics, we use the most advanced digital technology to custom design lenses for your glasses. Each lens is computer engineered and personalized to your unique prescription. Our optometrists are committed to finding the absolute best prescription for you. Drop by or book your eye exam now!"
        }
      />
      <div className="brands_car">
        <Carasoul img={frames} />
      </div>

      <div ref={sun}></div>
      <ProductsMain
        imgs={goggle_frames}
        heading={"Sunglasses"}
        color={true}
        text={
          "Our curated collection of Sunglasses are from the finest luxury designers around the Globe. Eliminate glare with our extremely comfortable polarized lenses. Our professionals will guide you the best lens material according to your lifestyle with a choice of different tints and sizes. Stop in and enjoy our selection!"
        }
      />
      <div className="brands_car">
        <Carasoul img={goggle} />
      </div>

      <div ref={contact}></div>
      <ProductsMain
        imgs={contact_lens_products}
        text={
          "Giving you the freedom to see the world clearly with our scientifically advanced Contact lenses. At Kohinoor Optics, we offer you contact lens consultation to help you with the right choice for your individual needs. Because your eyes deserve to discover the freedom"
        }
        heading={"Contact Lenses"}
      />
      <div className="brands_car">
        <Carasoul img={contact_lens} />
      </div>
    </div>
  );
}

export default Products;
