import "./App.scss";
import Header from "./Components/Header/Header";
import Homepage from "./Pages/Homepage/Homepage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Footer from "./Components/Footer/Footer";
import ContactUs from "./Pages/ContactUs/ContactUs";
import AboutUs from "./Pages/About/AboutUs";
import VisionCare from "./Pages/VisionCare/VisionCare";
import Products from "./Pages/Products/Products";
import Wa from "./Components/Wa";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Wa />
        <Switch>
          <Route path="/products">
            <Products />
          </Route>
          <Route path="/contact_us">
            <ContactUs />
          </Route>
          <Route path="/vision_care">
            <VisionCare />
          </Route>
          <Route path="/about_us">
            <AboutUs />
          </Route>
          <Route path="/">
            <Homepage />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
