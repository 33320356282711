import React from "react";
import "./Animtxt.scss";

function Animtxt() {
  return (
    <div class="animated-title">
      <div class="text-top">
        <div>
          <span>Be Awesome</span>
          <span>Be At Kohinoor</span>
        </div>
      </div>
      <div class="text-bottom">
        <div>
          Clearing Your Vision Since{" "}
          <span
            style={{
              color: "#722424",
            }}
          >
            {" "}
            1965
          </span>
        </div>
      </div>
    </div>
  );
}

export default Animtxt;
