import React from "react";
import "./ProductsMain.scss";

function ProductsMain(props) {
  const images = props.imgs;

  return (
    <div className={`products_main ${props.color && "products_main_color"}`}>
      <div className="products_title">
        <h1>{props.heading}</h1>
        <p>{props.text}</p>
      </div>

      <div className="products_images">
        {images.map((image) => {
          return (
            <div className="products_image">
              <img src={image} alt=""></img>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProductsMain;
