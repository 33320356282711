import React from "react";
import "./Button3D.scss";
import { NavLink } from "react-router-dom";
import { Tween } from "gsap/gsap-core";

function Button3D() {
  return (
    <button class="btn third">
      <NavLink to="/contact_us">Contact Us</NavLink>
    </button>
  );
}

export default Button3D;
