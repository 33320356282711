import React from "react";
import Animtxt2 from "../../Components/Animtxt2";
import DocProfile2 from "../../Components/DocProfile2";
import "./AboutUs.scss";
import { Controls, PlayState, Tween } from "react-gsap";
import Car3 from "../../Components/Carasoul/Car3";
import founder from "../../Assets/About/founder.svg";
import dharnidhar from "../../Assets/About/dharnidhar.svg";
import firststore from "../../Assets/About/firststore.svg";
import people from "../../Assets/About/people.svg";
import { Link } from "react-router-dom";
import onefive from "../../Assets/About/2015.svg";
import onenine from "../../Assets/About/2019.svg";
import shehnaz from "../../Assets/About/shehnax.svg";
import saeed from "../../Assets/saeed.svg";
import southBopal from "../../Assets/About/2019_2.svg";
import { useLayoutEffect } from "react";

function AboutUs() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const eye_wear = [
    "https://images.unsplash.com/photo-1572635196237-14b3f281503f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    "https://images.unsplash.com/photo-1581239125393-67d48d3dd429?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80",
    "https://images.unsplash.com/photo-1456081101716-74e616ab23d8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1055&q=80",
    "https://images.unsplash.com/photo-1456081101716-74e616ab23d8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1055&q=80",
    "https://images.unsplash.com/photo-1456081101716-74e616ab23d8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1055&q=80",
    "https://images.unsplash.com/photo-1456081101716-74e616ab23d8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1055&q=80",
  ];
  return (
    <div className="about_us">
      <div className="welcome_au" style={{ color: "#000" }}>
        <Animtxt2
          line1={"About Us"}
          line3={"Clearing Your Vision Since 1965"}
          color={true}
        />
        <button class="button">
          <Link to="/contact_us">
            <p>Contact Us</p>
          </Link>
        </button>
      </div>
      <div className="hero">
        <h1>WE ARE NOW IN THIS OVER 5 DECADES</h1>
      </div>
      <div className="welcome_services">
        <div className="services_txt">
          <Tween from={{ y: "200px" }} ease="elastic.out(0.2, 0.1)">
            <h1 style={{ color: "#000" }}>Kohinoor Optics</h1>
          </Tween>

          <Tween from={{ y: "200px" }} stagger={0.2} duration={0.5}>
            <p>
              Kohinoor optics is a consultant rather than a retailer and prides
              itself in curating a sophisticated and vast array of new, vintage,
              and custom eyewear brands from all over the world. We believe eye
              care and fashion go hand in hand. With our 56 years of experience
              and expertise in the field of optometry, we get to know each of
              our patients well enough to guide them expertly through the
              process of selecting the best pair of frames for their needs; We
              were inspired by a need to give spectacle wearers more choice in a
              market dominated by multiples and mass-produced budget eyewear.
            </p>
          </Tween>
        </div>
        <div className="services_img">
          {/* <img src="https://www.aamc.org/sites/default/files/styles/scale_and_crop_1200_x_666/public/Public%20Opinion%20Research%201200x666.jpg?itok=Z9g0DOPe"></img> */}
          <Car3 />
        </div>
      </div>
      {/* <div className="info_decade">
        <div className="decade_txt"></div>
        <div className="decade_img">
          <img
            src="https://image.freepik.com/free-photo/little-boy-store-trying-glasses_23-2148920029.jpg"
            alt=""
          ></img>
          <div></div>
        </div>
      </div> */}
      <DocProfile2
        right={false}
        img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/About%2Fdada.jpg?alt=media&token=fbbb6406-6640-4d2a-8dbf-4adb7a819a77"
        img2={founder}
      ></DocProfile2>
      <DocProfile2
        right={false}
        img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Stores%2Fcb158e9e-82bd-4546-a626-7a55e697013e.jpg?alt=media&token=f074b5fd-7308-4a1e-ac88-7a858421c7c6"
        img2={firststore}
      ></DocProfile2>
      <DocProfile2
        right={false}
        img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/About%2FWhatsApp%20Image%202021-09-15%20at%2002.15.08.jpeg?alt=media&token=12eb14e2-b089-486f-bafd-b6d3e625eb57"
        img2={people}
      ></DocProfile2>
      <DocProfile2
        right={false}
        img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Stores%2FWhatsApp%20Image%202021-09-15%20at%207.54.01%20PM-min.jpeg?alt=media&token=ea7fb054-8bdb-4593-bfc8-ee0b75c9c9b5"
        img2={dharnidhar}
      ></DocProfile2>
      <DocProfile2
        right={false}
        img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/About%2FWhatsApp%20Image%202021-09-14%20at%2022.52.50.jpeg?alt=media&token=97024523-fcc6-4091-8bd4-1d9aad9c8d72"
        img2={onefive}
      ></DocProfile2>
      <DocProfile2
        right={false}
        img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Stores%2FIMG_2677.JPG?alt=media&token=45b443a5-a7c6-4822-b2c9-1c86ece239fb"
        img2={southBopal}
      ></DocProfile2>
      <DocProfile2
        right={false}
        img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/About%2Fbopal.JPG?alt=media&token=7e78ea37-a257-400f-8db0-cc6070246e64"
        img2={onenine}
      ></DocProfile2>
      <DocProfile2
        right={false}
        img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/About%2FIMG_2898.JPG?alt=media&token=a9d08218-e242-4be2-8dc1-86be28dd4678"
        img2={saeed}
      ></DocProfile2>
      <DocProfile2
        right={false}
        img="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/About%2FIMG_2908.JPG?alt=media&token=a1f3c5eb-79db-4710-88f8-b33aea11af1a"
        img2={shehnaz}
      ></DocProfile2>
    </div>
  );
}

export default AboutUs;
