import React from "react";
import "./ContactUs.scss";
import cu_1 from "../../Assets/cu_1 (1).jpg";
import Animtxt3 from "../../Components/Animtxt3";
import TextG from "../../Components/TextG";
import { Reveal } from "react-gsap";
import Forms from "../../Components/Forms";
import blur_img from "../../Assets/blurEffect.svg";
import { Controls, PlayState, Tween } from "react-gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCoffee,
  faPhone,
  faPhoneAlt,
  faPhoneSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useLayoutEffect } from "react";

function ContactUs() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="contact_us">
      <div className="welcome_cu">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Homepage%2Fcu_1%20(1)-min.jpg?alt=media&token=79172112-b5e9-4955-968d-1ff66b748060"
          alt=""
        ></img>
        <div className="welcome_cu_text">
          <Animtxt3
            line1={"Contact Us"}
            line3={"Clearing Your Vision Since 1965"}
          />
        </div>
      </div>
      <div className="cu_info">
        <div className="info_txt">
          <Reveal repeat={false}>
            <TextG dur={2} txt={{ text: "Thank you for reaching us out" }}>
              <h1>TYFRUO</h1>
            </TextG>
          </Reveal>
          <h2>
            Please enter the below details If you have any Query and we’ll
            contact you
          </h2>
        </div>
      </div>
      <div className="cu_form">
        <div className="cu_form_img">
          <img src={blur_img} alt=""></img>
        </div>
        <div className="cu_form_txt">
          <Forms />
        </div>
      </div>
      <div className="cu_stores">
        <div className="stores_1">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Stores%2Fcb158e9e-82bd-4546-a626-7a55e697013e.jpg?alt=media&token=f074b5fd-7308-4a1e-ac88-7a858421c7c6"
            alt=""
          ></img>
          <Reveal>
            <Tween
              from={{ y: "50%", opacity: 0 }}
              duration={0.5}
              ease="back.out(1.7)"
            >
              <div class="overlay">
                <div class="text">
                  <p>255, SabuGali, ManekChowk, Ahmedabad-380001</p>
                  <FontAwesomeIcon icon={faPhoneAlt} className="phone_ion" />
                  <a href="tel:+917802838668">ManekChowk </a>
                  <br />

                  {/* <button class="button">Get Directions</button> */}
                </div>
                <button class="button">
                  <a href="https://www.google.com/maps/dir/23.0154608,72.4613188/kohinoor+optics+manekchowk/@23.0332804,72.4437043,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x395e848ac8a7faf3:0x42c6c65fc1b39b88!2m2!1d72.5659512!2d23.0395006">
                    {" "}
                    Get Directions
                  </a>
                </button>
              </div>
            </Tween>
          </Reveal>
        </div>
        <div className="stores_2">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Stores%2Fdharnidhar.jpg?alt=media&token=123775db-6a51-4caa-ba3c-ddb9523bbc60"
            alt=""
          ></img>
          <Reveal>
            <Tween
              from={{ y: "50%", opacity: 0 }}
              duration={0.8}
              ease="back.out(1.7)"
            >
              <div class="overlay">
                <div class="text">
                  <p>
                    3, Dharnidhar Tower Opp.Dharnidhar Derasar, Paldi
                    Ahmedabad-07
                  </p>
                  <FontAwesomeIcon icon={faPhoneAlt} className="phone_ion" />
                  <a href="tel:+919016092833">Dharnidhar </a>
                  <br />
                </div>
                <button class="button">
                  <a href="https://www.google.com/maps/dir/23.0154608,72.4613188/Kohinoor+Optics,+120+Feet+Ring+Road,+opp.+Dharnidhar+Jain+Temple,+Pankaj+Society,+Bhatta,+Paldi,+Ahmedabad,+Gujarat/@23.0176939,72.4748969,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x395e851eb0607c17:0x2dacd9cc0920d9dd!2m2!1d72.5583309!2d23.0072957">
                    {" "}
                    Get Directions
                  </a>
                </button>
              </div>
            </Tween>
          </Reveal>
        </div>
        <div className="stores_3">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Stores%2FIMG_2677.JPG?alt=media&token=45b443a5-a7c6-4822-b2c9-1c86ece239fb"
            alt=""
          ></img>
          <Reveal>
            <Tween
              from={{ y: "50%", opacity: 0 }}
              duration={1.1}
              ease="back.out(1.7)"
            >
              <div class="overlay">
                <div class="text">
                  <p>
                    7, Orchid Centre, opp. Safal Parisar 1, South Bopal,
                    Ahmedabad 380058
                  </p>
                  <FontAwesomeIcon icon={faPhoneAlt} className="phone_ion" />
                  <a href="tel:+919510626265">SOUTH BOPAL </a>
                  <br />
                </div>
                <button class="button">
                  <a href="https://www.google.com/maps/dir/23.0154608,72.4613188/kohinoor+optics/@23.0165951,72.462213,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x395e9ba4457648a3:0x26858c5e0e197afb!2m2!1d72.4705227!2d23.0155738">
                    {" "}
                    Get Directions
                  </a>
                </button>
              </div>
            </Tween>
          </Reveal>
        </div>
      </div>
      {/* <div className="emnam"></div> */}
    </div>
  );
}

export default ContactUs;
