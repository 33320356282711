import React from "react";
import Slider from "react-slick";
// import glass from "../../Assets/final_glass.svg"
// import specs from "../../Assets/Final_Specs.svg"
// import sunglasses from "../../Assets/Final_Sunglasses.svg"

import "./Car3.scss";

export default function Car3() {
  var settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  };
  return (
    <div className="car3">
      <Slider {...settings}>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Store%2FIMG_2679-min.JPG?alt=media&token=87b4c10d-1c68-4820-86ef-5f9c791237ae"
            alt=""
          ></img>
        </div>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Store%2FIMG_2680-min.JPG?alt=media&token=62c41cf8-fbc9-4c9d-9b72-4e674357632c"
            alt=""
          ></img>
        </div>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Store%2FIMG_2810-min.JPG?alt=media&token=cbe67304-4baf-49ef-b82f-4474f9f5071c"
            alt=""
          ></img>
        </div>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Store%2FIMG_2891-min.JPG?alt=media&token=c96095b0-9bbb-4a74-bfab-4584c426847d"
            alt=""
          ></img>
        </div>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Store%2FIMG_2924-min.JPG?alt=media&token=85a91b51-fac4-4a1e-8f49-6ef2509f34b4"
            alt=""
          ></img>
        </div>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Store%2FIMG_2937-min.JPG?alt=media&token=194112fa-3b34-4a69-98ce-20dd2a925d2e"
            alt=""
          ></img>
        </div>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/Store%2Fb25f80aa-6c40-4097-9d18-e6113beeebc8-min.jpg?alt=media&token=198efc25-5887-451c-885e-637ceb5bc184"
            alt=""
          ></img>
        </div>
      </Slider>
    </div>
  );
}
