import React from "react";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCoffee,
  faPhone,
  faPhoneAlt,
  faPhoneSquare,
} from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <div class="footer-clean">
      <footer>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-4 col-md-3 item">
              <h3> SOUTH BOPAL</h3>
              <ul>
                <p style={{ letterSpacing: "4.5px" }}>7 , Orchid Centre </p>
                <p style={{ letterSpacing: "3.5px" }}> Opp.Safal Parisar-1,</p>
                <p style={{ letterSpacing: "0px" }}>
                  {" "}
                  South Bopal, Ahmedabad-58
                </p>
              </ul>
            </div>
            <div class="col-sm-4 col-md-3 item">
              <h3 style={{ letterSpacing: "7px" }}>DHARNIDHAR</h3>
              <ul>
                <p style={{ letterSpacing: "3.5px" }}>3 , Dharnidhar Tower </p>
                <p>Opp.Dharnidhar Derasar </p>
                <p style={{ letterSpacing: "3.5px" }}> Paldi, Ahmedabad-07</p>
              </ul>
            </div>
            <div class="col-sm-4 col-md-3 item">
              <h3>Contact Us</h3>
              <ul>
                <FontAwesomeIcon icon={faPhoneAlt} className="phone_ion" />
                <a href="tel:+919510626265">SOUTH BOPAL </a>
                <br />
                <br></br>
                <FontAwesomeIcon icon={faPhoneAlt} className="phone_ion" />
                <a href="tel:+919016092833"> DHARNIDHAR </a>
              </ul>
            </div>
            <div class="col-lg-3 item social">
              <a href="https://m.facebook.com/profile.php?id=100053076620921">
                <i class="icon ion-social-facebook"></i>
              </a>
              <a href="https://wa.me/919510626265?text=I'm%20interested%20in%20your%20Services">
                <i class="icon ion-social-whatsapp"></i>
              </a>

              <a href="https://www.instagram.com/kohinooroptics/">
                <i class="icon ion-social-instagram"></i>
              </a>
              <p class="copyright">
                © 2021 Kohinoor Optics. All rights reserved
              </p>
              <br />

              <p class="copyright">
                {" "}
                Design and Developed by{" "}
                <a href="https://www.perfectweb.io">perfectweb.io</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
