import React from "react";
import "./DocProfile2.scss";
import { Controls, PlayState, Tween, Reveal } from "react-gsap";

function DocProfile2(props) {
  const istrue = props.right;
  if (istrue) {
    return (
      <div className="doc_profile2">
        <Reveal repeat={false}>
          <Tween
            from={{ x: "-100px" }}
            stagger={0.2}
            ease="elastic.out(0.2, 0.1)"
          >
            <div className="doc_des2">
              <img src={props.img2} alt=""></img>
            </div>
          </Tween>
        </Reveal>

        <Reveal repeat={false}>
          <Tween
            from={{ x: "100px" }}
            stagger={0.2}
            ease="elastic.out(0.2, 0.1)"
          >
            <div className="doc_img2">
              <img src={props.img} alt=""></img>
              <div></div>
            </div>
          </Tween>
        </Reveal>
      </div>
    );
  }
  return (
    <div className="doc_profile2">
      <Reveal repeat={false}>
        <Tween
          from={{ x: "-100px" }}
          stagger={0.2}
          ease="elastic.out(0.2, 0.1)"
        >
          <div className="doc_img2">
            <img src={props.img} alt=""></img>
            <div></div>
          </div>
        </Tween>
      </Reveal>
      <Reveal repeat={false}>
        <Tween from={{ x: "100px" }} stagger={0.2} ease="elastic.out(0.2, 0.1)">
          <div className="doc_des2">
            <img src={props.img2} alt=""></img>
          </div>
        </Tween>
      </Reveal>
    </div>
  );
}

export default DocProfile2;
