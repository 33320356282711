import React from "react";
import "./Ps.scss";
import Slider from "react-slick";

function Ps(props) {
  const images = props.img;
  var settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  };
  return (
    <div className="car2">
      <Slider {...settings}>
        {images.map((image) => {
          return (
            <div>
              <img src={image} alt=""></img>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default Ps;
